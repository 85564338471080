import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ image, alt, className, ...rest }) => (
	<GatsbyImage
		className={className}
		image={image.localFile.childImageSharp.gatsbyImageData}
		title={alt}
		alt={alt}
		{...rest}
	/>
);

export default Image;
