import React from 'react'
import Image from './image';
import '../styles/grid.scss';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const GRID_MAPPINGS = {
	LRR: {
		main: 'LRR_CONTAINER',
		images: ['LRR_RIGHT', 'LRR_RIGHT_1', 'LRR_RIGHT_2'],
	},
	LR: {
		main: 'LR_CONTAINER',
		images: ['LR_RIGHT', 'LR_LEFT'], 
	},
	LLR: {
		main: 'LLR_CONTAINER',
		images: ['LLR_RIGHT', 'LLR_LEFT_1', 'LLR_LEFT_2'],
	},
	TLR: {
		main: 'TLR_CONTAINER',
		images: ['TLR_TOP', 'TLR_LEFT', 'TLR_RIGHT'],
	},
	LMR: {
		main: 'LMR_CONTAINER',
		images: ['LMR_LEFT', 'LMR_MIDDLE', 'LMR_RIGHT'],
	},
	LRT: {
		main: 'LRT_CONTAINER',
		images: ['LRT_TOP', 'LRT_LEFT', 'LRT_RIGHT'],
	},
};

const Grid = ({ grid, images }) => {
	const currGrid = GRID_MAPPINGS[grid];
	const desktopImages = [...images].slice(0, currGrid.images.length);
	return (
		<>
			<div className={`main-grid ${currGrid.main}`}>
				{desktopImages.map(({ id, alt, image }, i) => (
					<Image
						key={id}
						className={`grid-item ${currGrid.images[i]}`}
						image={image}
						alt={alt}
					/>
				))}
			</div>
			<div className='mobile-grid'>
				<AwesomeSlider>
					{images.map(({ id, alt, image }, i) => (		
						<div className='grid-img' key={`mobile-${id}`}>
							<Image
								image={image}
								alt={alt}
							/>
						</div>
					))}
				</AwesomeSlider>
			</div>
		</>
	);
}

export default Grid;