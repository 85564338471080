import React from 'react'
import Grid from './grid';
import '../styles/room.scss';

const Room = ({ room: { grid, title, description: { html: descriptionHtml }, images } }) => (
	<div className='room'>
		<h2>{title}</h2> 
		<div className='description' dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
		{images &&
			<Grid grid={grid} images={images}/>
		}
	</div>
);

export default Room;
