import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import Room from '../components/room';
import Image from '../components/image';
import '../styles/main.scss';

const pageQuery = graphql`
  query pageQuery {
    home: allGraphCmsHome {
      edges {
        node {
          title
          description {
            html
          }
          coordinates {
            latitude
            longitude
          }
          neighbourhood {
            html
          }
          hero {
            alt
            image {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP], blurredOptions: { toFormat: WEBP }, quality: 80)
                }
              }
            }
          }
          rooms {
            id
            grid
            title
            description {
              html
            }
            images {
              id
              alt
              image {
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP], blurredOptions: { toFormat: WEBP }, quality: 80)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const { home } = useStaticQuery(pageQuery);
  const { title, description: { html: descriptionHtml }, hero, rooms, coordinates, neighbourhood } = home.edges[0].node;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>G11</title>
        <link rel="canonical" href="https://g11.mylo.is" />
        <meta property="og:title" content='G11' />
        <meta property="og:url" content='https://g11.mylo.is' />
        <meta property="og:description" content='Charming and fully furnished 2 bedroom apartment located in Fossvogur, Reykjavík available for rent' />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      </Helmet>
      <div className='container'>
        <div className='top-row'>
          <h1>{title}</h1>
          <a href="#contact" className='contact'>Contact</a>
        </div>
        <div className='top'>
          <div className='text' dangerouslySetInnerHTML={{ __html: descriptionHtml }}/>
          <Image
            className='hero'
            image={hero.image}
            alt={hero.alt}
            imgStyle={{ objectPosition: 'top'}}
          />
        </div>
        {rooms.map((r) => (
          <Room room={r} key={r.id} />
        ))}
        <Room room={{ title: 'The neighbourhood', description: neighbourhood }} />
        <div className='contact-container' id='contact'>
          <p>
            <h2 className='contact-header'>
              For more information
            </h2>
            <p className='contact-text'>Please contact:</p>
            <div>
              <div className='contact-row'>
                <a href="tel:+3548478068">+354 847-8068</a>
                <p className='mh-1'>
                  |
                </p>
                <a href="mailto:arnar@bonsai.is">arnar@bonsai.is</a>
              </div>
            </div>
          </p>
        </div>
      </div>
    </>
  )
};

export default IndexPage;
